var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "LBLDETAIL" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && _vm.isOld
                        ? _c("c-btn", {
                            attrs: { label: "LBLREMOVE", icon: "delete" },
                            on: { btnClicked: _vm.deleteContruction },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              isSubmit: _vm.isSave,
                              url: _vm.saveUrl,
                              param: _vm.construction,
                              mappingType: _vm.mappingType,
                              label: "LBLSAVE",
                              icon: "save",
                            },
                            on: {
                              beforeAction: _vm.saveConstruction,
                              btnCallback: _vm.saveConstructionCallback,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        editable: _vm.editable,
                        label: "LBL0001803",
                        name: "constructionName",
                      },
                      model: {
                        value: _vm.construction.constructionName,
                        callback: function ($$v) {
                          _vm.$set(_vm.construction, "constructionName", $$v)
                        },
                        expression: "construction.constructionName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        required: true,
                        editable: _vm.editable,
                        range: true,
                        label: "LBL0001804",
                        name: "period",
                      },
                      model: {
                        value: _vm.construction.period,
                        callback: function ($$v) {
                          _vm.$set(_vm.construction, "period", $$v)
                        },
                        expression: "construction.period",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-vendor", {
                      attrs: {
                        editable: _vm.editable,
                        label: "LBL0001807",
                        name: "vendorCd",
                      },
                      model: {
                        value: _vm.construction.vendorCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.construction, "vendorCd", $$v)
                        },
                        expression: "construction.vendorCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable: _vm.editable,
                        isFlag: true,
                        label: "LBL0001811",
                        name: "constructionFinishFlag",
                      },
                      model: {
                        value: _vm.construction.constructionFinishFlag,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.construction,
                            "constructionFinishFlag",
                            $$v
                          )
                        },
                        expression: "construction.constructionFinishFlag",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        type: "number",
                        label: "LBL0001812",
                        name: "safetyHealthManageCost",
                      },
                      model: {
                        value: _vm.construction.safetyHealthManageCost,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.construction,
                            "safetyHealthManageCost",
                            $$v
                          )
                        },
                        expression: "construction.safetyHealthManageCost",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "LBL0001813",
                        name: "costCenter",
                      },
                      model: {
                        value: _vm.construction.costCenter,
                        callback: function ($$v) {
                          _vm.$set(_vm.construction, "costCenter", $$v)
                        },
                        expression: "construction.costCenter",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "LBL0001814",
                        name: "onSiteManager",
                      },
                      model: {
                        value: _vm.construction.onSiteManager,
                        callback: function ($$v) {
                          _vm.$set(_vm.construction, "onSiteManager", $$v)
                        },
                        expression: "construction.onSiteManager",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        label: "LBL0001806",
                        name: "safetyManager",
                      },
                      model: {
                        value: _vm.construction.safetyManager,
                        callback: function ($$v) {
                          _vm.$set(_vm.construction, "safetyManager", $$v)
                        },
                        expression: "construction.safetyManager",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        rows: 2,
                        label: "LBL0001815",
                        name: "constructionDetails",
                      },
                      model: {
                        value: _vm.construction.constructionDetails,
                        callback: function ($$v) {
                          _vm.$set(_vm.construction, "constructionDetails", $$v)
                        },
                        expression: "construction.constructionDetails",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "c-table",
        {
          ref: "vendortable",
          attrs: {
            title: "LBL0001816",
            columns: _vm.gridVendor.columns,
            data: _vm.construction.vendors,
            gridHeight: _vm.gridVendor.height,
            editable: _vm.editable,
            hideBottom: true,
            isExcelDown: false,
            filtering: false,
            isFullScreen: false,
            columnSetting: false,
            selection: "multiple",
            rowKey: "vendorCd",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLEXCEPT", icon: "remove" },
                        on: { btnClicked: _vm.deleteVendor },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addVendor },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }